import React from "react";
import NumberInput from "../../Tools/NumberInput";

export default function RentPrice({ register, errors, data, back }) {
  return (
    <div class="other_div other_div2 sp2 row">
      <h3 className="mb-4">{("What’s Your Asking Price?")}</h3>
      <div className="mb-4">
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {("Price in dollar")}
        </h5>
        <div class={`form-group ${back == false ? "rentPriceMargin" : ""}`}>
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Daily")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice}
              event={register}
              name={"dollorDaily"}
              placeholder={"$"}
            />
            {errors.dollorDaily && <p>{errors.dollorDaily?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Weekly")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice1}
              event={register}
              name={"dollorWeekly"}
              placeholder={"$"}
            />
            {errors.dollorWeekly && <p>{errors.dollorWeekly?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Monthly")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice2}
              event={register}
              name={"dollorMonthly"}
              placeholder={"$"}
            />
            {errors.dollorMonthly && <p>{errors.dollorMonthly?.message}</p>}
          </div>
        </div>
      </div>





      <div>
        {/* <h3 className="mb-3">{t("What’s Your Asking Price?") + " (IQD) "}*</h3> */}
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {("Price in iraqi dinar")}*
        </h5>
        <div class={`form-group ${back == false ? "rentPriceMargin" : ""}`}>
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Daily")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice}
              event={register}
              name={"daily_price"}
              placeholder={"IQD"}
            />
            {errors.daily_price && <p>{errors.daily_price?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Weekly")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice1}
              event={register}
              name={"weekly_price"}
              placeholder={"IQD"}
            />
            {errors.weekly_price && <p>{errors.weekly_price?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{("Monthly")}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice2}
              event={register}
              name={"monthly_price"}
              placeholder={"IQD"}
            />
            {errors.monthly_price && <p>{errors.monthly_price?.message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
