import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import { aprrovalAdvertColumn, aprrovalUserColumn } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import { singlePendingApprovalFun } from "../../../Reducer/user/singlePendingApprovalSlice";
import {
  deletePendingAprrovalFun,
  getGlobalSettings,
  setGlobalSettings,
} from "../../../services/api";
import SingleBoxItem from "../../Tools/SingleBoxItem";
import { fetchDelete, fetchView } from "../../Functions/DataTable";
import { ITEM_PER_PAGE } from "../../../data/varible";
import { apiManager } from "../../../data/apiHandler";
import { Button, ButtonGroup, FormGroup, Input } from "reactstrap";
import BoostrapModel from "../../Tools/BoostrapModel";
import swal from "sweetalert";
import ConfirmToggle from "./toggleModal";
import { useDidMountEffect } from "../../../data/hooks";

const AdvertApproval = () => {
  const localStorageKeys = {
    currPage: "currPageAdsApprovalQueue",
    tabUserType: "tabAdsApprovalQueue",
  };
  const [submitting, setSubmitting] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);
  const [action, setAction] = React.useState([]);
  const [userApproval, setUserApproval] = useState(false);
  const [dealerApproval, setDealerApproval] = useState(false);
  const [userType, setUserType] = useState(
    localStorage.getItem(localStorageKeys.tabUserType) || "all"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState("");
  //toogle modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  // Pagination state
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem(localStorageKeys.currPage)) || 1
  );
  const limit = ITEM_PER_PAGE;
  const apiGetCalls = async () => {
    setSubmitting(true);
    const uType =
      userType === "all" ? "" : userType === "private" ? "user" : "dealer";
    const allApiCalls = await Promise.all([
      apiManager(
        "GET",
        `opp/filteredAds?lang=en&limit=${limit}&page=${currentPage}&approveStatus=pending&isAdCompleted=YES&userType=${uType}`
      ),
      getGlobalSettings(),
    ])
    .catch((err) => {
      console.log(err);
      setSubmitting(false);
    });


    const res1 = allApiCalls[0]
    const res2 = allApiCalls[1]
    if (res1.status) {
      let data = res1?.data?.ads.map((item) => ({
        ...item,
        createdAt: moment.unix(item.adCreatedAt).format("DD/MM/YYYY"),
        item: `${item?.properties?.basicDetails?.make?.en} ${item?.properties?.basicDetails?.model?.en} ${item?.properties?.basicDetails?.makeYear}`,
        name:
          item?.owner?.dealerShipName &&
          item?.owner?.dealerShipName !== "N/A"
            ? item?.owner?.dealerShipName
            : item?.owner?.fullName && item?.owner?.fullName !== "N/A"
            ? item?.owner?.fullName
            : "--",
        email: item?.owner?.email,
        phoneNumber: item?.owner?.phoneNumber,
        address: item?.owner?.address,
        userType: item?.owner?.userType === "user" ? "Private" : "Dealer",
        adType: item?.adTypeName?.en,
      }));
      setRow(data);
      setTotalCount(res1?.data?.totalAds);
      setTotalPages(res1?.data?.totalPages);
    } else {
      console.log(res1);
    }

    if (res2?.status) {
      setUserApproval(res2?.data?.requireUserAdApproval);
      setDealerApproval(res2?.data?.requireDealerAdApproval);
    }
    fetchActions();
    setSubmitting(false)
  };

  useEffect(() => {
    apiGetCalls();
  }, [dispatch, currentPage]);

  useDidMountEffect(() => {
    setCurrentPage(1);
    apiGetCalls();
  }, [userType]);

  const fetchActions = async () => {
    const edit = fetchView(
      "Edit",
      singlePendingApprovalFun,
      "reveiw_approval_que",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      singlePendingApprovalFun,
      "/ad_approval_que/reveiw_approval_que_advert",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      deletePendingAprrovalFun,
      dispatch,
      "",
      getAllUserFun
    );
    setAction([viewNoraml, edit, deleted]);
  };

  const view = {
    label: "",
    image: "eye2.png",
    moreParams: "&approving=true",
    onclick: (id) => null,
    path: "/view_advert",
    disabled: true,
  };
  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append("time", data);
    dispatch(getAllUserFun(formData));
  };

  const handleChangeToggle = (type, val) => {
    if (submitting) return;
    const value = val ? "YES" : "NO";
    if (type === "dealer") {
      setGlobalSettings({ requireDealerAdApproval: value }).then((res) => {
        if (res?.status) {
          setDealerApproval(val);
        } else {
          swal("Error", res?.message, "error");
        }
      });
    } else {
      setGlobalSettings({ requireUserAdApproval: value }).then((res) => {
        if (res?.status) {
          setUserApproval(val);
        } else {
          swal("Error", res?.message, "error");
        }
      });
    }
    console.log(type, val);
  };

  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">
        Pending {userType} advert approvals
      </div>
      <div className="w-100 d-flex justify-content-end mb-4">
        <div
          class="btn-group border my-tabs"
          style={{ borderRadius: "4px", height: "43px" }}
          role="group"
          aria-label="Basic example"
        >
          <button
            disabled={submitting}
            type="button"
            class={`btn btn-light ${userType == "all" ? "activeButton" : ""}`}
            style={{ width: "150px" }}
            onClick={() => {
              setUserType("all");
              localStorage.setItem(localStorageKeys.tabUserType, "all");
              // setData(tradeUsers);
            }}
          >
            All
          </button>
          <button
            disabled={submitting}
            type="button"
            class={`btn btn-light ${
              userType == "dealer" ? "activeButton" : ""
            }`}
            style={{ width: "150px" }}
            onClick={() => {
              setUserType("dealer");
              localStorage.setItem(localStorageKeys.tabUserType, "dealer");
              // setData(indiviualUsers);
            }}
          >
            Dealer
          </button>
          <button
            disabled={submitting}
            type="button"
            class={`btn btn-light ${
              userType == "private" ? "activeButton" : ""
            }`}
            style={{ width: "150px" }}
            onClick={() => {
              setUserType("private");
              localStorage.setItem(localStorageKeys.tabUserType, "private");
              // setData(indiviualUsers);
            }}
          >
            Private Users
          </button>
        </div>
      </div>
      <div className="d-block d-lg-flex" style={{ gap: "10px" }}>
        <div className=" p-2 mb-3 w-100 rounded-3 border bg-white d-flex justify-content-between align-items-center">
          <h4 className="fs-6">Approval for Dealer Adverts</h4>
          <FormGroup switch className="d-flex align-items-center mb-2">
            <Input
              type="switch"
              checked={dealerApproval}
              style={{ fontSize: "20px" }}
              onChange={(e) =>
                openModal({ type: "dealer", val: e.target.checked })
              }
            />
          </FormGroup>
        </div>
        <div className="p-2 mb-3 w-100 rounded-3 border bg-white d-flex justify-content-between align-items-center">
          <h4 className="fs-6">Approval for Private Adverts</h4>
          <FormGroup switch className="d-flex align-items-center mb-2">
            <Input
              type="switch"
              checked={userApproval}
              style={{ fontSize: "20px" }}
              onChange={(e) =>
                openModal({ type: "private", val: e.target.checked })
              }
            />
          </FormGroup>
        </div>
      </div>
      <BasicTable
        actionType={"dropdown"}
        columns={aprrovalAdvertColumn ? aprrovalAdvertColumn : []}
        rows={row ? row : []}
        // actions={action}
        // view={view}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        submitting={submitting}
        onRowClick={(e) =>
          navigate(`${view.path}?id=${e?._id}${view.moreParams || ""}`)
        }
        totalPages={totalPages}
        localCurrPageKey={localStorageKeys.currPage}
      />
      <SingleBoxItem
        parentStyle={"indiv"}
        childStyle={"st"}
        heading={totalCount}
        description={"Pending Aprrovals"}
      />
      <BoostrapModel
        show={modal}
        heading={"Confirm Approval"}
        style={"warning warningKyc"}
        fullWidth
        inlineStyle={{
          paddingRight: "0px !important",
        }}
        component={
          <ConfirmToggle
            closeModal={() => setModal(false)}
            handleSubmit={handleChangeToggle}
            data={modalData}
          />
        }
        onHide={() => setModal(false)}
      />
    </div>
  );
};
export default AdvertApproval;
