import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { getAllUserFun } from "../../../Reducer/user/userSlice";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import { approvalUserColumn } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import { singlePendingApprovalFun } from "../../../Reducer/user/singlePendingApprovalSlice";
import { deletePendingAprrovalFun } from "../../../services/api";
import SingleBoxItem from "../../Tools/SingleBoxItem";
import { fetchDelete, fetchView } from "../../Functions/DataTable";
import { ITEM_PER_PAGE } from "../../../data/varible";
import { styled } from "@mui/material";

const ApprovalQue = () => {
  const row = useSelector((state) => state.user?.user);
  const totalPages = useSelector((state) => state.user?.totalPages);
  const totalCount = useSelector((state) => state.user?.totalCount);
  const submitting = useSelector((state) => state.user?.submitting);
  const pendingApprovals = useSelector(
    (state) => state.user?.user?.pending_approvals
  );

  const error = useSelector((state) => state.user.error);
  const [action, setAction] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const localStorageKeys = {
    currPage: "currPageAccountsApprovalQueue",
    tab: "tabAccountsApprovalQueue",
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem(localStorageKeys.currPage)) || 1
  );
  const limit = ITEM_PER_PAGE;
  React.useEffect(() => {
    dispatch(
      getAllUserFun({ time: "This Weeks", page: currentPage, limit: limit })
    );
    fetchActions();
  }, [dispatch]);

  const fetchActions = async () => {
    const edit = fetchView(
      "Edit",
      singlePendingApprovalFun,
      "reveiw_approval_que",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      singlePendingApprovalFun,
      "/approval_que/reveiw_approval_que",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      deletePendingAprrovalFun,
      dispatch,
      "",
      getAllUserFun
    );
    setAction([viewNoraml, edit, deleted]);
  };

  const view = {
    label: "",
    image: "eye2.png",
    onclick: (id) => {
      console.log("viewId", id);
    },
    disabled: true,
    path: "/reveiw_approval_que",
  };
  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append("time", data);
    dispatch(getAllUserFun(formData));
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  const data =
    row &&
    row?.length > 0 &&
    row?.map((item) => {
      return {
        ...item,
        createdAt: moment.unix(item.createdAt).format("DD/MM/YYYY"),
        profilePicture: (
          <div className="d-flex justify-content-start h-100">
            <img
              style={{ objectFit: "cover" }}
              height={40}
              width={40}
              className="rounded-circle"
              src={item?.profilePicture || "assets/images/ap2.png"}
            />
          </div>
        ),
        rawData: item,
      };
    });
  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">
        {"Pending dealer account approvals"}
      </div>
      <BasicTable
        actionType={"dropdown"}
        columns={approvalUserColumn ? approvalUserColumn : []}
        rows={data ? data : []}
        // actions={action}
        // view={view}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        submitting={submitting}
        totalPages={totalPages}
        onRowClick={(e) =>
          navigate(`${view.path}?id=${e?._id}${view.moreParams || ""}`, {
            state: { item: e?.rawData },
          })
        }
        localCurrPageKey={localStorageKeys.currPage}
      />
      <SingleBoxItem
        parentStyle={"indiv"}
        childStyle={"st"}
        heading={totalCount}
        description={"Pending Aprrovals"}
      />
    </div>
  );
};
export default ApprovalQue;
