import React from "react";
import NumberInput from "../../Tools/NumberInput";
// import { useTranslation } from "react-i18next";
import SelectForm from "../../Tools/SelectForm";
export default function SellPrice({
  register,
  errors,
  finance,
  data,
  language,
}) {
  return (
    <div class="other_div other_div2 sp">
      <h3>{"What’s Your Asking Price?"}</h3>
      <div className="mb-4">
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {"Price in dollar"}
        </h5>
        <div class="form-group">
          <div class="form-control" style={{ paddingLeft: "12px" }}>
            <NumberInput
              left={true}
              intype={"text"}
              value={data?.dollorAskPrice}
              event={register}
              name={"dollorPrice"}
              placeholder={"$"}
            />
            {errors.dollorPrice && (
              <p className="priceBoxErrors">{errors.dollorPrice?.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {"Price in iraqi dinar"}*
        </h5>
        <div class="form-group">
          <div class="form-control" style={{ paddingLeft: "12px" }}>
            <NumberInput
              left={true}
              intype={"text"}
              value={data?.askPrice}
              event={register}
              name={"asking_price"}
              placeholder={"IQD"}
            />
            {errors.asking_price && (
              <p className="priceBoxErrors">{errors.asking_price?.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h3>{"Finance"}</h3>
        <SelectForm
          style={"form-control"}
          event={register}
          name={"finance"}
          selectedOption={data?.properties?.finance}
          values={finance}
        />
        {errors.finance && <p>{errors.finance.message}</p>}
      </div>
    </div>
  );
}
